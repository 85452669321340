const UUID_KEY = "JH_UUID";
const TOKEN_KEY = "JH_TOKEN";
const CURRENT_SURVEY = "JH_CURRENT_SURVEY";
const USER_INFO = "USER_INFO";
const TOKEN_KEY_HEALTH = "TOKEN_KEY_HEALTH";
const INVEST_SURVEY = "INVEST_SURVEY";
const INVEST_VALUE = "INVEST_VALUE";
const ARCHIVES_SURVEY = "ARCHIVES_SURVEY";
const ARCHIVES_VALUE = "ARCHIVES_VALUE";
const ARCHIVES_VALUE_SCORE = "ARCHIVES_VALUE_SCORE";
const FORM_VALUE = "FORM_VALUE";
const STAFF_TOKEN = "JH_STAFF_TOKEN";
const WECHAT_STAFF_TOKEN = "WECHAT_STAFF_TOKEN";
const STAFF_INFO = "JH_STAFF_INFO";
const UI_CACHE_DB_DICT_DATA = "UI_CACHE_DB_DICT_DATA";

// dict key
const inHospital_time = "inHospital_time"; //入院时间
const diagnosis_category = "diagnosis_category"; //入院目的
const in_hospital_status = "in_hospital_status"; //入院申请状态
const ns_nurse = "ns_nurse"; //转运护士
const ns_dirver = "ns_dirver"; //转运司机
const ns_car = "ns_car"; //转运车辆
const ns_bed = "ns_bed"; //收治床位
const ns_ward = "ns_ward"; //拟收病区
const history_guan = "history_guan"; //带管情况
const history_pic_type = "history_pic_type"; //病史附件类型
const history_department = "history_department"; //病史科室
const yn = "yn"; //默认联系人
const history_hospital = "history_hospital"; // 病史医院
const yn_type = "yn_type"; // 是否是新健康周期
const patient_status = "patient_status"; // 患者状态
const decision_type = "decision_type"; // 家庭决策人
const religion = "religion"; // 宗教信仰
const pay_type = "pay_type"; // 支付方式
const patient_relation = "patient_relation"; // 与患者关系
const crm_hospital_area = "crm_hospital_area"; // crm系统院区
const sex = "sex"; // 性别
const bed_req = "bed_req"; // 床位要求
const in_hos_type = "in_hos_type"; //入院方式
const treatment_category = "treatment_category"; //治疗分类
const card_type = "card_type"; //卡的类型
const apply_type = "apply_type"; // 申请形式
const to_user_know = "to_user_know"; // 申请告知书
const explain_type = "explain_type";//患者备注说明
const remarks_show="remarks_show";//患者备注是否显示
const content_type="content_type";//内容类型
const emergency_level="emergency_level";// 紧急程度
const remarks_exe_status="remarks_exe_status";// 处理状态
const jy_type = "jy_type";//   检验状态
const jc_type = "jc_type";//   检查状态
const patient_tag = "patient_tag";//   患者标签
const visit_type='visit_type'//探访类型
const subspecialty='subspecialty'//探访类型
const COLORS = {
	"WAIT-AUDIT": "#5f5aa3", // 待患服审核
	REJECT: "rgba(227, 70, 108, 1)", // 入院驳回
	"WAIT-IN": "rgba(103, 193, 31, 1)", // 待入院
	"CANCEL-IN": "rgba(241, 153, 99, 1)", // 取消入院
	"PRE-OUT": "rgba(47, 148, 221, 1)", // 预出院
	"WAIT-OUT": "rgba(47, 148, 221, 1)", // 待出院
	OUT: "#5f5aa3", // 已出院
	"IN-HOSPITAL": "#5f5aa3" // 已入院
};

export default {
	INVEST_SURVEY,
	INVEST_VALUE,
	ARCHIVES_SURVEY,
	ARCHIVES_VALUE,
	ARCHIVES_VALUE_SCORE,
	COLORS,
	UI_CACHE_DB_DICT_DATA,
	UUID_KEY,
	TOKEN_KEY,
	USER_INFO,
	CURRENT_SURVEY,
	TOKEN_KEY_HEALTH,
	STAFF_TOKEN,
	WECHAT_STAFF_TOKEN,
	FORM_VALUE,
	STAFF_INFO,
	dict: {
		card_type,
		inHospital_time,
		diagnosis_category,
		in_hospital_status,
		ns_nurse,
		ns_dirver,
		ns_car,
		ns_bed,
		ns_ward,
		history_guan,
		history_pic_type,
		history_department,
		yn,
		history_hospital,
		yn_type,
		patient_tag,
		patient_status,
		decision_type,
		religion,
		pay_type,
		patient_relation,
		crm_hospital_area,
		sex,
		bed_req,
		in_hos_type,
		treatment_category,
		apply_type,
		to_user_know,
		//add
		content_type,
		emergency_level,
		remarks_exe_status,
		explain_type,
		remarks_show,
		jy_type,
		jc_type,
		visit_type,
		subspecialty
	}
};
