<template>
	<div class="crm-bg crm-create">
		<div class="crm-tip" v-if="apply.status === 'REJECT'">
			<p>{{ apply.remarks }}</p>
			<a href="javascript:;" class="crm-tip-btn" type="primary" @click="edit">修改入院申请信息</a>
		</div>
		<div class="crm-card">
			<div class="crm-card-title">
				<div class="crm-card-title__flex">
					转诊信息
					<van-tag class="card-card-title-tag" :color="apply.status | tagColor">{{ apply.status | convertDict(in_hospital_status) }}</van-tag>
				</div>
				<span class="crm-card-cancel" @click="cancel">取消入院申请</span>
			</div>
			<div class="crm-card-body">
				<van-field readonly :value="apply.applyType | convertDict(apply_type)" name="apply_type" label="申请形式" />
				<van-field
					v-if="apply.applyType != 'oneself'"
					readonly
					:value="apply.zzHospital | convertDict(history_hospital)"
					name="zzHospital"
					:label="`${apply.applyType == 'again' ? '(原)' : ''}转诊医院`"
				/>
				<van-field
					v-if="apply.applyType != 'oneself' && apply.zzHospital == 'other'"
					readonly
					:value="apply.zzHospitalOther"
					name="zzHospitalOther"
					label="其他转诊医院"
				/>
				<van-field
					v-if="apply.applyType != 'oneself'"
					readonly
					:value="apply.zzDepartment | convertDict(history_department)"
					name="zzDepartment"
					:label="`${apply.applyType == 'again' ? '(原)' : ''}转诊科室`"
				/>
				<van-field
					v-if="apply.applyType != 'oneself' && apply.zzDepartment == 'other'"
					readonly
					:value="apply.zzDepartmentOther"
					name="zzDepartmentOther"
					label="其他转诊科室"
				/>
				<van-field v-if="apply.applyType != 'oneself'" readonly :value="apply.consultationDoctor" name="consultationDoctor" label="会诊医生" />
				<!-- <van-field readonly :value="apply.zzTreatmentCategory | convertDict(treatment_category)" name="zzTreatmentCategory" label="治疗分类" /> -->
				<van-field readonly :value="apply.zzDiagnosisCategory | convertDict(diagnosis_category)" name="zzDiagnosisCategory" label="入院目的" />
				<van-field
					v-if="apply.zzDiagnosisCategory == 'other'"
					readonly
					:value="apply.zzDiagnosisCategoryOther"
					name="consultationDoctor"
					label="其他入院目的"
				/>
				<van-field readonly :value="apply.nsInTime | convertDict(inHospital_time)" name="nsInTime" label="入院时间" />
				<van-field readonly :value="apply.nsWard | convertDict(ns_ward)" label="入院病区" />
        <van-field v-if="apply.nsWard === '嘉进A3病区' && apply.subspecialty" readonly :value="sys_dict.subspecialtys.find(v => v.value === apply.subspecialty).text" label="亚专科组" />
				<van-field readonly :value="apply.zzPlanInDate" name="zzPlanInDate" label="拟入院日" />
				<van-field readonly :value="apply.zzInType | convertDict(in_hos_type)" name="zzInType" label="入院方式" />
				<van-field readonly :value="apply.zzPlanInArea | convertDict(crm_hospital_area)" name="zzPlanInArea" label="拟入院区" />
				<van-field readonly :value="apply.zzBedReq | convertDict(bed_req)" name="zzBedReq" label="床位要求" />
			</div>
		</div>
		<div class="crm-card">
			<div class="crm-card-title">病史概要</div>
			<div class="crm-card-body">
				<CRMCheckbox
					:readonly="true"
					name="history_guan"
					:isRequired="false"
					label="带管情况"
					:value="history.withGuan.split(',')"
					:options="history_guan | convertOptionDict(history.withGuan)"
				/>
				<van-field
					v-if="(history.withGuan || '').indexOf('other') > -1"
					v-model="history.withGuanOther"
					name="withGuanOther"
					class="mt-10"
					label="其他"
					readonly
					placeholder="请输入其他带管情况"
				/>
			</div>
		</div>
		<div class="crm-card" v-if="outRemarks.length > 0">
			<div class="crm-card-body">
				<CRMTextarea
					v-for="(item, index) in outRemarks"
					:key="index"
					readonly
					:label="'出院诊断 ' + (index + 1)"
					:rows="4"
					:value="item"
					:class="index > 0 ? 'mt-20' : ''"
					placeholder="请输入诊断内容......"
				/>
			</div>
		</div>
		<div class="crm-card">
			<div class="crm-card-title">病史类型</div>
			<div class="crm-card-body">
				<ImgField :label="history.picType | convertDict(history_pic_type)" :options="history.pic | convertImg" />
			</div>
		</div>
		<!-- <div class="crm-card mb-80 pb-28">
			<div class="crm-card-body">
				<CRMTextarea readonly label="备注" :rows="4" v-model="apply.remarks" placeholder="请输入备注内容......" />
			</div>
		</div> -->
		<div class="crm-btn-inline mb-32" v-if="apply.status != 'REJECT'">
			<van-button class="crm-btn" block @click="toChange">修改</van-button>
		</div>
	</div>
</template>

<script>
import CRMCheckbox from "@/components/CRM/Checkbox";
import CRMTextarea from "@/components/CRM/Textarea";
import ImgField from "@/components/CRM/ImgField";
import api from "@/request/api";
import crmMIxins from "@/utils/crm-mixins";
import { Dialog, Notify } from "vant";
import { mapGetters } from "vuex";
import request from "@/request";
import Vue from "vue";
import TYPES from "@/constant/types.js";
export default {
	name: "crm-apply-detail",
	mixins: [crmMIxins],
	components: { ImgField, CRMCheckbox, CRMTextarea },
	data() {
		return {
			loading: false,
			apply: {
				zzHospital: "",
				zzDepartment: "",
				zzDiagnosisCategory: "",
				zzPlanInDate: "",
				zzInType: "",
				zzPlanInArea: "",
				zzBedReq: "",
				withGuan: "",
				outRemarks: "",
				nsWard: "",
			},
			history: {
				pic: "",
				picType: "",
				withGuan: "",
				outRemarks: "",
			},
			outRemarks: [],
			url: {
				authMenuList: "/crm-system/sys/mobileMenu/authMenuList",
			},
      sys_dict: {
        subspecialtys: []
      }
		};
	},
	mounted() {
		this.getDetail(this.$route.params.id);
    this.sys_dict.subspecialtys = Vue.ls.get(TYPES.UI_CACHE_DB_DICT_DATA)['subspecialty'];
	},
	computed: {
		...mapGetters(["userInfo"]),
	},
	methods: {
		edit() {
			const { apply } = this;
			if (apply.status === "REJECT") {
				Dialog.confirm({ title: "提示", message: "被驳回的申请需要先取消后再次发起申请" }).then(() => {
					api.crmInHospitalApplyCancel(apply.id).then((res) => {
						if (res.success) {
							this.jumpPage("/crm/create-apply/" + apply.patientId + "?applyId=" + apply.id);
						} else {
							Notify({ type: "danger", message: "取消失败！" });
						}
					});
				});
			} else {
				this.jumpPage("/crm/edit-apply/" + apply.id);
			}
		},
		cancel() {
			Dialog.confirm({ title: "提示", message: "您确定取消该患者的入院申请？" }).then(() => {
				api.crmInHospitalApplyCancel(this.apply.id).then((res) => {
					if (res.success) {
						this.$toast.success("取消成功!");
						this.$router.back();
					} else {
						console.log(res.message);
						Notify({ type: "danger", message: res.message });
					}
				});
			});
		},
		getDetail(id) {
			api.crmInHospitalApplyDetail(id).then((res) => {
				if (res.success) {
					this.apply = res.result;
				}
			});
			api.crmMedicalHistoryList({ relationId: id, relationType: "hospital" }).then((res) => {
				if (res.success && res.result.records.length > 0) {
					this.history = res.result.records[0];
					if (!!this.history.outRemarks && this.history.outRemarks.indexOf("[") === 0) {
						this.outRemarks = JSON.parse(this.history.outRemarks);
					} else {
						this.outRemarks = [this.history.outRemarks];
					}
				}
			});
		},
		getUrl(array, url) {
			for (let i = 0; i < array.length; i++) {
				let obj = array[i];
				if (obj.url === url) {
					return obj;
				}
				if (obj.child) {
					let result = this.getUrl(obj.child, url);
					if (result) return result;
				}
			}
			return null;
		},

		toChange() {
    const { apply, staffInfo, url, getUrl, jumpPage } = this;
    const userName = staffInfo.username;

    // 提前检查权限
    if (apply.createBy !== userName && userName !== "admin") {
        Dialog.alert({
            title: "提示",
            message: "您不是此信息的创建者，因此无法编辑。",
        }).then(() => {
            // on close
        });
        return;
    }

    // 封装处理请求响应的逻辑
    const handleResponse = (res) => {
		if (res.success) {
            const patientData = getUrl(res.result, "/crm/patient");
            if (patientData && Array.isArray(patientData.child) && patientData.child.length > 0) {
                this.zzName = patientData.child[0].name;

            } else {
                // 处理child为空或不存在的情况
                // console.error('无法获取zzName，因为child为空或不存在');

                this.zzName = ''; // 或者设置为某个默认值
            }
        } else {
            Notify({ type: "danger", message: res.message });
        }
    };

    // 发送请求
    request.get(url.authMenuList).then((res) => {
        handleResponse(res);

        if (apply.status === "WAIT-IN") {
            Dialog.confirm({
                title: "提示",
                message: "是否要修改入院信息！",
                confirmButtonText: "确认"
            }).then((res) => {
                jumpPage(`/crm/create-apply/${apply.patientId}?applyId=${apply.id}&type=edit&zzName=${this.zzName}`);
            });
        } else {
            Dialog.confirm({
                title: "提示",
                message: "修改入院信息时，不允许修改拟入院日期！",
                confirmButtonText: "知晓"
            }).then((res) => {
                jumpPage(`/crm/create-apply/${apply.patientId}?applyId=${apply.id}&type=edit&zzName=${this.zzName}`);
            });
        }
    });
},
	},
};
</script>

<style lang="less">
.crm-tip {
	font-weight: 600;
	font-size: 28px;
	line-height: 39px;
	color: #5f5aa3;
	text-align: center;
	padding-bottom: 48px;
	p {
		padding: 40px 0 25px;
	}
}
.crm-tip-btn {
	display: inline-block;
	background: #5f5aa3;
	border-radius: 10px;
	font-weight: 400;
	font-size: 32px;
	line-height: 45px;
	color: #ffffff;
	padding: 10px 32px;
}
</style>
